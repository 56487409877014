<template>
  <section class="gao-de-map">
    <!--    地图      -->
    <div id="container" style="position: relative"></div>
    <div
      style="
        color: #000;
        z-index: 999;
        position: absolute;
        bottom: 0px;
        padding-left: 5px;
      "
    >
      <div class="scroll-container">
        <ul ref="scrollList" class="scroll-list">
          <li
            v-for="(item, index) in informationList"
            :key="index"
            style="height: 110px"
          >
            <div style=" padding: 10px; line-height: 25px;">
              <div>{{ item.apartmentName }}</div>
              <div>开始时间:&nbsp;&nbsp;{{ item.date }}</div>
              <div>房租金额:&nbsp; <span style="color: rgb(243, 201, 25);font-weight: bold;"> {{ item.amount }} 元</span></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { getScrollInformation } from '@/api/data-screen'

const community = require('@/assets/images/data-screen/blank.png')
export default {
  name: 'center-map',
  props: {
    // 点标记
    markers: {
      type: Array,
      default: () => []
    },
    markerContent: Function
  },
  data () {
    return {
      map: null,
      loca: null,
      AMap: null,
      Loca: null,
      informationList: [], // 滚动信息列表
      timer: null,
      currentIndex: 0,
      scrollSpeed: 30 // 滚动速度，单位为像素/秒
    }
  },
  watch: {
    markers: {
      handler () {
        this.$nextTick(() => {
          this.createMarkerCluster()
        })
      },
      deep: true
    }
  },
  methods: {
    async createMarkerCluster () {
      await this.clearMapMarker()
      let markers = []
      for (const item of this.markers) {
        const { longtitude, latitude } = item
        if (!longtitude || !latitude) continue
        const lnglat = [longtitude, latitude]
        // 生成点标记
        let marker = new this.AMap.Marker({ position: lnglat, offset: [0, 15] })
        marker.setIcon(this.createMarkerIcon())
        marker.setLabel({
          content: `<div class='info'>${item.name}</div>`,
          direction: 'bottom'
        })
        marker.on('click', () => {
          this.createInfoWindow(item)
        })
        markers.push(marker)
      }
      this.map.add(markers)
      if (markers.length > 0) {
        this.createMapLoca()
      }
    },
    // 自定义图标
    createMarkerIcon () {
      return new this.AMap.Icon({
        size: new this.AMap.Size(30, 30),
        image: community,
        imageSize: new this.AMap.Size(30, 30)
      })
    },
    createInfoWindow (data) {
      const { longtitude, latitude } = data
      const position = [longtitude, latitude]
      const infoWindow = new this.AMap.InfoWindow({
        position,
        offset: new this.AMap.Pixel(0, -15),
        content: this.markerContent(data)
      })
      infoWindow.open(this.map)
    },
    async clearMapMarker () {
      // 清空点标记
      const { map } = await this.initGaoDeMap()
      map.clearMap()
    },

    async createMapLoca () {
      const { ALoca, loca } = await this.initGaoDeMap()
      loca.clear()
      const data = { type: 'FeatureCollection', features: [] }
      for (const i of this.markers) {
        const { longtitude, latitude, name } = i
        const para = {
          type: 'Feature',
          properties: { name },
          geometry: { type: 'Point', coordinates: [] }
        }
        if (latitude && longtitude) {
          para.geometry.coordinates = [longtitude, latitude]
          data.features.push(para)
        }
      }

      const geoLevelF = new ALoca.GeoJSONSource({ data })
      const scatter = new ALoca.ScatterLayer({
        opacity: 1,
        visible: true,
        zooms: [2, 20]
      })
      scatter.setSource(geoLevelF)
      scatter.setStyle({
        unit: 'px',
        size: [30, 30],
        borderWidth: 0,
        texture:
          'https://a.amap.com/Loca/static/loca-v2/demos/images/breath_red.png',
        duration: 600,
        animate: true
      })
      loca.add(scatter)
      loca.animate.start()
    },

    initGaoDeMap () {
      const that = this
      // 初始化完成进行地图初始化
      return new Promise((resolve, reject) => {
        if (that.map && that.AMap && that.ALoca && that.loca)
          return resolve({
            map: that.map,
            AMap: that.AMap,
            ALoca: that.ALoca,
            loca: that.loca
          })
        AMapLoader.load({
          key: '1428638687b08afb032d2ed539051647',
          version: '2.0',
          plugins: [
            'AMap.PlaceSearch',
            'AMap.AutoComplete',
            'AMap.MarkerCluster'
          ],
          Loca: {
            version: '2.0.0'
          }
        })
          .then(AMap => {
            // 默认中心点是当前ip的定位
            this.AMap = AMap
            const ALoca = window.Loca
            const map = new AMap.Map('container', {
              zoom: 13,
              viewMode: '3D',
              resizeEnable: true,
              pitch: 40,
              center: [108.32, 22.85907]
              //   mapStyle: 'amap://styles/darkblue'
            })
            this.map = map
            that.ALoca = ALoca
            const loca = new ALoca.Container({ map })
            this.loca = loca
            return resolve({ map, AMap, ALoca, loca })
          })
          .catch(e => {
            console.log('初始化完成进行地图初始化', e)
            reject(e)
          })
      })
    },
    // 2023.07.24张晓瑜新增
    // 获取滚动信息数据
    async getScrollinformationList () {
      const { list } = await getScrollInformation()
      console.log('滚动列表信息', list)
      this.informationList = list
    },
    //设置滚动数据
    // startScroll () {
    //   this.timer = setInterval(() => {
    //     const scrollList = this.$refs.scrollList
    //     const firstItem = scrollList.firstChild
    //     const itemHeight = firstItem.offsetHeight
    //     const marginTop = this.currentIndex * -itemHeight
    //     // 设置过渡效果实现滚动
    //     scrollList.style.transition = `margin-top ${
    //       itemHeight / this.scrollSpeed
    //     }s linear`
    //     scrollList.style.marginTop = `${marginTop}px`
    //     this.currentIndex++
    //     if (this.currentIndex >= this.informationList.length) {
    //       this.currentIndex = 0
    //       // 等待过渡效果完成后，取消过渡效果并重设margin-top值
    //       setTimeout(() => {
    //         scrollList.style.transition = ''
    //         scrollList.style.marginTop = '0'
    //       }, (itemHeight / this.scrollSpeed) * 1000)
    //     }
    //   }, 2500) // 滚动间隔，单位为毫秒
    // }
    startCarousel() {
      setInterval(() => {
        this.carouselInfos.push(this.carouselInfos.shift());
      }, 15000); // 每5秒轮播一次，可以根据需要调整时间间隔
    }

  },
  created () {
    // 获取滚动信息列表
    this.getScrollinformationList()
    this.startCarousel();
  },
  mounted () {
    // DOM初始化完成进行地图初始化
    // this.initGaoDeMap();
    // 开启滚动
    this.startScroll()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.gao-de-map {
  width: 100%;
  height: 100%;
  position: relative;

  #container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .map-tools {
    position: absolute;
    top: VH(20px);
    right: VW(20px);
  }

  /deep/ .amap-markers {
    .amap-marker-label {
      padding: VH(10px) VW(15px);
      border: none;
      border-radius: 5px;
      box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
    }
  }
}
// 滚动信息
.scroll-container {
  width: 100%;
  height: 210px;
  overflow: hidden;
}

.scroll-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 500px;
  animation: carousel-scroll 23s linear infinite;
}
.scroll-list li {
  background-image: url('../../../../assets/../assets/images/data-screen/3fb73a9b157f4f03273b89daaf57000.png');
  background-repeat: no-repeat;
  color: #fff;
  font-size: 14px;
  width: 240px;
}
@keyframes carousel-scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-150%);
  }
}
</style>
